import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import {
  EvRangeMapImagesModel,
  EvRangeMapImagesPaths,
  MAP_NOT_AVAILABLE,
} from 'client/data/models/ev-range-map-images';
import { Link } from 'site-modules/shared/components/link/link';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';
import { ShareThisChart } from 'site-modules/shared/components/share-this-chart/share-this-chart';
import { EVRangeMapImageUI } from 'site-modules/shared/components/ev-range-map-image/ev-range-map-image';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { VisitorEntities } from 'client/data/models/visitor';

export function fireTrackingZipChange(value) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
      subaction_name: TrackingConstant.ACTION_PROVIDE_ZIP_CODE,
      action_category: TrackingConstant.USER_ACTION_CATEGORY,
      action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
      creative_id: 'edm-entry-range',
      value,
    },
  });
}
export function RangeInsightMapUI({
  rangeMapUrl,
  pathname,
  vehicleName,
  visitorLocation,
  onTooltipOpen,
  isTestedRange,
  isInternational,
}) {
  const isMissingMapImage = rangeMapUrl && rangeMapUrl === MAP_NOT_AVAILABLE;
  const mapRangeMiles = rangeMapUrl && +get(rangeMapUrl.match(/\/(\d+)-miles\./), '[1]');
  const mapRangeMilesRoundTrip = mapRangeMiles && Math.ceil(mapRangeMiles / 50) * 25;

  return (
    <div className="range-insight-map">
      <EVRangeMapImageUI
        url={rangeMapUrl}
        className="mb-1"
        fireTrackingZipChange={fireTrackingZipChange}
        isInternational={isInternational}
      />
      {!isInternational && (
        <div className="rounded-10 bg-cool-gray-100 py-1 px-0_5 text-center">
          {isMissingMapImage ? (
            'No range map available for'
          ) : (
            <Fragment>
              Estimated range map
              <TooltipItem
                className="top-0"
                item={{
                  placement: 'bottom',
                  text: (
                    <Fragment>
                      This map is a visual representation of the possible one-way and round-trips by this vehicle (on a
                      full charge) from the geometric center of {get(visitorLocation, 'city')},{' '}
                      {get(visitorLocation, 'state')}. The depicted ranges are based on the estimated new vehicle range
                      value provided by the EPA, rounded down to {mapRangeMiles} miles for one-way and{' '}
                      {mapRangeMilesRoundTrip} miles for round-trip. Actual range will vary depending on the condition
                      of this vehicle’s battery pack, how you drive, driving conditions and other factors.
                      <div>
                        Map display generated using: ©&nbsp;
                        <Link to="openrouteservice.org" className="text-underline" rel="nofollow" target="_blank">
                          openrouteservice.org
                        </Link>{' '}
                        by HeiGIT | Map data ©&nbsp;
                        <Link
                          to="https://www.openstreetmap.org/#map=5/38.007/-95.844"
                          className="text-underline"
                          rel="nofollow"
                          target="_blank"
                        >
                          OpenStreetMap
                        </Link>{' '}
                        contributors |{' '}
                        <Link to="https://leafletjs.com/" className="text-underline" rel="nofollow" target="_blank">
                          Leaflet
                        </Link>
                      </div>
                    </Fragment>
                  ),
                }}
                id="range-map"
                delay={{ show: 0, hide: 250 }}
                autohide={false}
                onTooltipOpen={onTooltipOpen}
              />{' '}
              from
            </Fragment>
          )}{' '}
          <ZipWithDefaultActionHandling buttonClassName="size-16 fw-normal" />
        </div>
      )}
      <ShareThisChart
        pageTitle={vehicleName}
        pageUrl={`https://www.edmunds.com${pathname}#subnav-ev-insights`}
        shareText="Share"
        className={classnames('text-blue-40 rounded', {
          'mx-auto mt-1': isTestedRange,
          'pos-a top-0 right-0 mt-1_5 me-1_5': !isTestedRange,
        })}
        trackingId="view_share_options"
        socialIcons={['facebook', 'x', 'linkedin', 'reddit', 'email']}
        socialTrackingId="share_range_map"
      />
    </div>
  );
}

RangeInsightMapUI.propTypes = {
  rangeMapUrl: PropTypes.string,
  pathname: PropTypes.string,
  vehicleName: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  onTooltipOpen: PropTypes.func,
  isTestedRange: PropTypes.bool,
  isInternational: PropTypes.bool,
};

RangeInsightMapUI.defaultProps = {
  rangeMapUrl: null,
  pathname: null,
  vehicleName: null,
  visitorLocation: null,
  onTooltipOpen: null,
  isTestedRange: false,
  isInternational: false,
};

export const propsAreEqual = (prevProps, { rangeMapUrl }) => !rangeMapUrl;
export const RangeInsightMap = connectToModel(React.memo(RangeInsightMapUI, propsAreEqual), {
  pathname: bindToPath('location.pathname', PageModel),
  rangeMapUrl: bindToPath(
    ({ currentRange, isInternational }) =>
      !isInternational && EvRangeMapImagesPaths.RANGE_MAP_CURRENT_CITY(currentRange),
    EvRangeMapImagesModel
  ),
});
