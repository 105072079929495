import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getStaticImageUrl } from 'client/utils/image-helpers';
import { CHARGE_PORT } from 'site-modules/shared/constants/ev-insights';
import { TooltipItem } from 'site-modules/shared/components/tooltip-item/tooltip-item';
import { Link } from 'site-modules/shared/components/link/link';

export function ChargingInsight({ chargeTime, testedChargeTime, portType, onTooltipOpen, noAdditionalCopy }) {
  useEffect(() => {
    if (testedChargeTime) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
        event_data: {
          action_name: TrackingConstant.ACTION_SHOW_CONTENT,
          subaction_name: 'show_fast_charging_time',
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chargePort = CHARGE_PORT[portType];

  return (
    <div className="charging-insight" data-tracking-parent="edm-entry-charging">
      {!!chargePort && (
        <div className="bg-cool-gray-100 py-1 px-1 text-center radius-10 mb-2">
          <div className="fw-bold text-cool-gray-30 mb-0_25">
            {portType}
            <TooltipItem
              className="top-0 medium"
              item={{
                placement: 'bottom',
                text: chargePort.description,
              }}
              id="charging-port-insight"
              onTooltipOpen={onTooltipOpen}
            />
          </div>
          <div className="mb-1">Plug Type</div>
          <img src={getStaticImageUrl(chargePort.image)} alt="" height={117} />
        </div>
      )}
      {chargeTime || testedChargeTime ? (
        <Fragment>
          <div className="charging-times d-flex justify-content-center text-center mb-1">
            {!!chargeTime && (
              <div className={classnames('medium', { 'separator px-1 px-lg-0_5 px-xl-1': testedChargeTime })}>
                <div className="heading-4 mb-0_25 text-nowrap">{chargeTime} hrs</div>
                240V Home Charging
                <TooltipItem
                  className="top-0 small"
                  item={{
                    placement: 'bottom',
                    text:
                      'Estimated total charging time when using a 240V outlet. This is either a 3-prong or 4-prong outlet used for appliances, for example a clothes dryer.',
                  }}
                  id="home-charging"
                  onTooltipOpen={onTooltipOpen}
                />
              </div>
            )}
            {!!testedChargeTime && (
              <div className={classnames('medium', { 'px-1 px-lg-0_5 px-xl-1': chargeTime })}>
                <div className="heading-4 mb-0_25">{Math.round(parseFloat(testedChargeTime))} min</div>
                <div className="mb-0_5">
                  DC Fast Charging
                  <TooltipItem
                    className="top-0 small"
                    item={{
                      placement: 'bottom',
                      text:
                        "This is the time needed to charge the vehicle's battery from a 10% to an 80% charge level, employing a fast charger at the maximum power the vehicle can accept. To optimize charging speed, we activate the vehicle's battery preconditioning feature, if it has one. In the absence of a preconditioning feature, we drive a sufficient amount to ensure the battery pack is warm and ready for charging.",
                    }}
                    id="fast-charging"
                    onTooltipOpen={onTooltipOpen}
                  />
                </div>
                <div
                  className="bg-gray-lightest px-0_25 py-0_25 rounded fw-medium small text-nowrap"
                  style={{ letterSpacing: '1px' }}
                >
                  edmunds{' '}
                  <span className="fw-bold" style={{ letterSpacing: '3px' }}>
                    TESTED
                  </span>
                </div>
              </div>
            )}
          </div>
          {!noAdditionalCopy && (
            <>
              <div className="medium mb-1">EV batteries should be kept at 30-80% charged to extend battery life.</div>
              <Link
                to="/car-news/every-ev-you-can-charge-at-a-supercharger.html"
                className="medium text-cool-gray-40 text-underline"
              >
                When can I use NACS/Tesla charging stations?
              </Link>
            </>
          )}
        </Fragment>
      ) : (
        <div>No charging time information available</div>
      )}
    </div>
  );
}

ChargingInsight.propTypes = {
  chargeTime: PropTypes.number,
  testedChargeTime: PropTypes.string,
  portType: PropTypes.string,
  onTooltipOpen: PropTypes.func,
  noAdditionalCopy: PropTypes.bool,
};

ChargingInsight.defaultProps = {
  chargeTime: null,
  testedChargeTime: null,
  portType: null,
  onTooltipOpen: noop,
  noAdditionalCopy: false,
};
